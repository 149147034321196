export default function maxOccuranceBy(arr, key) {
  const occurance = {};
  let maxOccurance = 0;
  let maxOccuranceKey;

  arr?.forEach((obj) => {
    const occuranceKey = obj[key];
    if (occurance[occuranceKey]) {
      occurance[occuranceKey] += 1;
    } else {
      occurance[occuranceKey] = 1;
    }

    if (occurance[occuranceKey] > maxOccurance) {
      maxOccurance = occurance[occuranceKey];
      maxOccuranceKey = occuranceKey;
    }
  });
  return maxOccuranceKey;
}

import React from "react";
import { styled } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import AgentTextWall from "@/game-val/AgentTextWalls.jsx";
import { AGENT_COLORS } from "@/game-val/constants.mjs";
import { getAgentImage } from "@/game-val/utils.mjs";
import { classNames } from "@/util/class-names.mjs";
import keyInObject from "@/util/key-in-object.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const CardFrame = styled("div")`
  position: relative;
  width: var(--width, 152px);
  height: var(--height, calc(var(--width, 160px) * 0.5));
  background: var(--shade8);
  border-radius: var(--br);

  &.without-overflow {
    overflow: hidden;

    .agent-image {
      height: 100%;
    }
  }

  &.cropped {
    ${mobile} {
      overflow: hidden;
    }

    .agent-image {
      ${mobile} {
        transform: translate(-50%, 20%);
      }
    }
  }

  &.mobile-hide-textwall {
    .agent-textwall-container {
      ${mobile} {
        display: none;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
  }

  &::before {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--background-color, "transparent");
    border-radius: var(--br);
    opacity: 0.2;
    z-index: 1;
  }

  .agent-image.hide {
    display: none;
  }

  .agent-image {
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 115%;
    width: auto;
    transform: translateX(-50%);
    /* mask-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), black 20%); */
    z-index: 2;
  }

  .agent-textwall-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  svg.agent-textwall {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0);
    fill: var(--agent-color, var(--shade2));
    opacity: 0.75;
    transition: var(--transition);
    z-index: 1;
  }
`;

const AgentCutout: React.FC<{ agentKey: string }> = ({ agentKey }) => {
  const {
    val: {
      meta: {
        agents: { list },
      },
    },
  } = useSnapshot(readState);

  return list?.map((a) => {
    const src = getAgentImage(a.key, "cutout");
    return (
      <img
        key={a.key}
        src={src}
        {...classNames("agent-image", a.key !== agentKey && "hide")}
      />
    );
  });
};

interface AgentCardProps {
  agentKey: string;
  width?: number;
  height?: number;
  noBG?: boolean;
  cropped?: boolean;
  preserveTextwall?: boolean;
  withoutOverflow?: boolean;
}

const AgentCard: React.FC<AgentCardProps> = ({
  agentKey,
  width,
  height,
  noBG = false,
  cropped,
  preserveTextwall,
  withoutOverflow,
}) => {
  const agentColor = keyInObject(AGENT_COLORS, agentKey)
    ? AGENT_COLORS[agentKey]
    : null;

  return (
    <CardFrame
      style={{
        "--agent-color": agentColor ? `hsl(${agentColor})` : null,
        "--background-color":
          noBG || !agentColor ? "transparent" : `hsl(${agentColor})`,
        "--width": width ? `${width}px` : null,
        "--height": height ? `${height}px` : null,
      }}
      {...classNames(
        withoutOverflow && "without-overflow",
        !preserveTextwall && "mobile-hide-textwall",
        cropped && "cropped",
      )}
    >
      <div className="agent-textwall-container">
        <AgentTextWall className="agent-textwall" agentKey={agentKey} />
      </div>
      <AgentCutout agentKey={agentKey} />
    </CardFrame>
  );
};

export default AgentCard;

import React, { forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

import AbilityIcon from "@/game-val/AgentAbilityIcon.jsx";
import DifficultyDot from "@/game-val/DifficultyDot.jsx";
import { DIFFICULTY_OPTIONS } from "@/game-val/guides-utils.mjs";
import ChevronLeft from "@/inline-assets/chevron-left.svg";
import ValHeadshot from "@/inline-assets/val-headshot.svg";

export const diffFallback = {
  easy: "Easy",
  medium: "Medium",
  pro: "Pro",
};

const TipIcon = styled("div")`
  margin-right: var(--sp-4);
`;

const TipContent = styled("div")`
  flex: 1;
`;

const AbilityFrame = styled("div")`
  position: relative;
  box-sizing: border-box;
  padding: var(--sp-1);
  background: var(--shade10);
  border-radius: 50%;
  border: var(--sp-0_5) solid transparent;

  .selected & {
    border-color: var(--agent-color-light, "inherit");

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--agent-color, "inherit");
      border-radius: 50%;
      opacity: 0.2;
    }
  }

  svg {
    height: var(--sp-8);
    color: var(--agent-color-light, "inherit");

    ${mobile} {
      height: var(--sp-6);
    }
  }
`;

const HeadshotIconWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(var(--sp-1) * 10);
  height: calc(var(--sp-1) * 10);
  background: rgba(187, 201, 224, 0.15);
  border: var(--sp-0_5) solid var(--shade1);
  border-radius: 50%;

  svg {
    width: var(--sp-6);
    height: var(--sp-6);
    color: var(--shade1);
  }
`;

const Tip = styled("li", forwardRef)`
  box-sizing: border-box;
  position: relative;
  display: flex;
  user-select: none;
  padding: var(--sp-4) var(--sp-6);
  cursor: pointer;

  ${mobile} {
    padding: var(--sp-3) var(--sp-4);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0%;
    height: 100%;
    width: 0;
    background: transparent;
    transition: var(--transition);
  }

  &:hover,
  &.hover,
  &.selected {
    background: var(--shade6);

    .tip-tag {
      background: var(--shade5);
    }
  }

  &.selected {
    &::before {
      width: 3px;
      background: var(--agent-color);
    }
  }

  .tip-header svg {
    width: var(--sp-6);
    color: var(--shade1);
    transform: rotate(-90deg);

    ${mobile} {
      display: none;
    }
  }

  &:not(.selected) {
    .tip-description {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &.selected {
    .tip-header svg {
      transform: rotate(90deg);
    }
  }

  .tip-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--sp-1);
  }
  .tip-title {
    width: 75%;

    ${mobile} {
      width: 100%;
    }
  }
  .tip-description {
    color: var(--shade2);
  }
  .tip-tags {
    display: flex;
    margin-top: var(--sp-2);
  }
  .tip-tag {
    display: flex;
    align-items: center;
    height: var(--sp-6);
    padding: var(--sp-0_5) var(--sp-2_5);
    background: var(--shade6);
    border-radius: var(--br);

    &:not(:last-child) {
      margin-right: var(--sp-1);
    }

    svg {
      font-size: var(--sp-3);
      color: var(--shade2);
      margin-right: var(--sp-0_5);
    }
  }
`;

const Ability = ({ abilityKey }) => (
  <AbilityFrame>
    <AbilityIcon className="ability-icon" ability={abilityKey} />
  </AbilityFrame>
);

const ListItem = (props, ref) => {
  const {
    tip,
    selectedTip,
    updateFilters,
    hoveredTip,
    setHoveredTip,
    clickable,
    isHeadshot,
    agents,
  } = props;
  const { id, agent, title, description, icon, difficulty } = tip;

  const { t } = useTranslation();
  const isTipSelected = selectedTip?.id === id;

  const handleTipClick = useCallback(
    (e) => {
      const isSelected = e.currentTarget?.dataset?.selected;

      if (isSelected === "true") {
        updateFilters("guide", null);
      } else {
        updateFilters("guide", id);
      }
    },
    [id, updateFilters],
  );

  const handleMouseOver = useCallback(
    (e) => {
      const tip_title = e.currentTarget.dataset.title;
      setHoveredTip(tip_title);
    },
    [setHoveredTip],
  );

  const handleMouseOut = useCallback(() => {
    setHoveredTip(null);
  }, [setHoveredTip]);

  if (!title) return null;

  const tipClasses = `${isTipSelected && "selected"} ${
    hoveredTip === title && "hover"
  }`;
  const difficultyObj = DIFFICULTY_OPTIONS.find((d) => d.value === difficulty);

  const agentObj = agents.find((a) => a.key === agent);

  return (
    <Tip
      ref={ref}
      className={tipClasses}
      onClick={clickable && handleTipClick}
      onMouseOver={clickable && handleMouseOver}
      onMouseOut={clickable && handleMouseOut}
      data-selected={isTipSelected}
      data-title={title}
    >
      {agentObj && (
        <TipIcon>
          <Ability abilityKey={icon} />
        </TipIcon>
      )}
      {isHeadshot && (
        <TipIcon>
          <HeadshotIconWrapper>
            <ValHeadshot />
          </HeadshotIconWrapper>
        </TipIcon>
      )}
      <TipContent>
        <div className="tip-header">
          <span className="type-subtitle2">{title}</span>
          {clickable && <ChevronLeft />}
        </div>
        <p className="type-caption tip-description">{description}</p>
        <div className="tip-tags">
          {difficultyObj && (
            <div className={`tip-tag tip-difficulty`}>
              <DifficultyDot difficulty={difficulty} />
              <span className="type-caption--bold">
                {t(...difficultyObj.text)}
              </span>
            </div>
          )}
        </div>
      </TipContent>
    </Tip>
  );
};

export default forwardRef(ListItem);

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import BlitzArrowDropdown from "@/inline-assets/blitz-caret-down.svg";
import BlitzClose from "@/inline-assets/close.svg";

const MapsContainer = styled("div")`
  position: relative;
  box-sizing: border-box;
  padding: var(--sp-18) var(--sp-6) var(--sp-3);
  background: var(--shade9);
  border-top-left-radius: var(--br);
  border-top-right-radius: var(--br);

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    border-top-left-radius: var(--br);
    border-top-right-radius: var(--br);
    mask-image: linear-gradient(to bottom, black, rgba(0, 0, 0, 0.4));
  }
`;
const Title = styled("div")`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .arrow {
    height: var(--sp-6);
  }
`;
const MenuBtn = styled("button")`
  appearance: none;
  background: transparent;
  color: inherit;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
`;
const MapSelect = styled("div")`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  padding: var(--sp-4);
  background: var(--shade10);
  border-radius: var(--br);
  z-index: 1;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--sp-2);

    .header-close {
      cursor: pointer;
      color: var(--shade2);
    }
  }

  .map-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--sp-2);

    .map {
      position: relative;
      text-align: center;
      border-radius: var(--br-sm);
      padding: var(--sp-8) var(--sp-4) var(--sp-2);
      overflow: hidden;
      cursor: pointer;

      &:hover {
        .map-img {
          transform: translate(-50%, -50%) scale(1.1);
        }
      }
    }
    .map-img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      transform: translate(-50%, -50%);
      mask-image: linear-gradient(to bottom, black, rgba(0, 0, 0, 0.4));
      transition: var(--transition);
    }
    .map-name {
      position: relative;
    }
  }
`;

const MapSelector = ({ maps, selectedMap, onChange }) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <MapsContainer>
      <div
        className="background-image"
        style={{ backgroundImage: `url(${selectedMap.images.hero})` }}
      />
      <Title>
        <h6 className="type-h6">
          {t(`val:maps.${selectedMap.key}`, selectedMap.name)}
        </h6>
        <MenuBtn onClick={() => setMenuOpen((setMenuOpen) => !setMenuOpen)}>
          <BlitzArrowDropdown className="arrow" />
        </MenuBtn>
        {menuOpen && (
          <MapSelect>
            <div className="header">
              <span className="type-caption--bold">
                {t("val:selectMap", "Select a Map")}
              </span>
              <div className="header-close" onClick={() => setMenuOpen(false)}>
                <BlitzClose />
              </div>
            </div>
            <div className="map-list">
              {maps.map((map) => (
                <div
                  key={map.key}
                  className="map"
                  onClick={() => {
                    setMenuOpen(false);
                    onChange(map.key);
                  }}
                >
                  {map.images.hero && (
                    <img src={map.images.hero} className="map-img" />
                  )}
                  <span className="type-caption--bold map-name">
                    {t(`val:maps.${map.key}`, map.name)}
                  </span>
                </div>
              ))}
            </div>
          </MapSelect>
        )}
      </Title>
    </MapsContainer>
  );
};

export default MapSelector;
